// import logo from './logo.svg';
// import bg from'./bg.png';
import './App.css';
function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <div className='head'>
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        </div>
      </header> */}
      <div className="main">
        {/* <img src = {bg} alt='风里雨里，未来可期'></img> */}
      </div>
      <div className='fooder'>
      <div class="gs-bottombar-container">
        <div class="bottom-text">
          <div>
            Copyright © ixiapi Music Interactive  All Rights
            Reserved <br></br>虾皮音乐
          </div>
          <div>
            备案号：
            <a 
            href="http://beian.miit.gov.cn" 
            target="_blank"
            rel="noopener noreferrer"
            >
              冀ICP备17026709号-2
            </a>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default App;
